import { LitElement, html, css, PropertyValues } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { Ayetu } from '@ayetu/sdk-js';

interface AlmostDoneEventData {
  otp: string;
}

@customElement('almost-done')
export class HandleExists extends LitElement {
  static styles = css`
    .almost-done {
      width: 100%;
      margin: 4px 2px;
      position: relative;
    }
    .almost-done--search,
    .almost-done--name {
      width: 100%;
      height: 34px;
      border-radius: 5px;
      border: 1px solid #e9e9e9;
      text-indent: 12px;
      box-sizing: border-box;
      color: #505b67;
      background-color: #ebeced;
      background-repeat: no-repeat;
      background-position: right 10px center;
      padding-right: 36px;
      margin-bottom: 10px;
    }
    .almost-done--search.available {
      background-image: url('./images/pass.svg');
    }
    .almost-done--search.not-available {
      background-image: url('./images/fail.svg');
    }
    .almost-done--search.empty {
      background-image: none;
    }
    .submit-button {
      display: block;
      width: 100%;
      height: 34px;
      border-radius: 5px;
      background-color: #4caf50;
      color: white;
      border: none;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
      margin-top: 10px;
    }
    .submit-button:hover {
      background-color: #45a049;
    }
  `;

  @property({ type: Boolean })
  debugMode: boolean = false;

  @property({ type: String })
  inputValue: string = '';

  @property({ type: String })
  nameValue: string = '';

  @state()
  handleAvailable: boolean | null = null;

  @state()
  otp: string | null = null;

  private debounceTimeout: number | undefined;

  constructor() {
    super();
    if (this.debugMode) {
      console.log('Debug mode is enabled');
    }
    this.otp = localStorage.getItem('otp');
  }

  updated(changedProperties: PropertyValues) {
    if (changedProperties.has('debugMode')) {
      if (this.debugMode) {
        console.log('Debug mode is enabled');
      } else {
        console.log('Debug mode is disabled');
      }
    }
  }

  handleInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.inputValue = input.value;
    if (this.debugMode) {
      console.log(`Input value changed to: ${this.inputValue}`);
    }

    clearTimeout(this.debounceTimeout);

    if (this.inputValue) {
      this.debounceTimeout = window.setTimeout(() => {
        this.checkHandleExists(this.inputValue);
      }, 500);
    } else {
      this.handleAvailable = null;
      this.requestUpdate();
    }
  }

  handleNameChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.nameValue = input.value;
    if (this.debugMode) {
      console.log(`Name value changed to: ${this.nameValue}`);
    }
  }

  async checkHandleExists(handle: string) {
    if (!handle.startsWith('@')) {
      handle = `@${handle}`;
    }
    try {
      const res: boolean = await Ayetu.auth.checkHandle(handle);
      this.handleAvailable = res;
      this.requestUpdate();
      if (this.debugMode) {
        console.log(`Handle availability checked: ${res ? 'available' : 'not available'}`);
      }
    } catch (error) {
      console.error('Error fetching handle availability:', error);
      this.handleAvailable = false;
    }
  }

  async handleSubmit() {
    if (this.debugMode) {
      console.log(`Submitting Name: ${this.nameValue}, Handle: ${this.inputValue}`);
    }

    const handle = this.inputValue;
    const name = this.nameValue;

    console.log('handleZZ', handle);
    console.log('nameCC', name);

    try {
      if (handle !== null && name !== null) {
        console.log('handle2', handle);
        console.log('name2', name);
        await Ayetu.auth.submit(handle, name);
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    let inputClass = 'almost-done--search empty';

    if (this.inputValue) {
      inputClass =
        this.handleAvailable === null
          ? 'almost-done--search'
          : this.handleAvailable
          ? 'almost-done--search available'
          : 'almost-done--search not-available';
    }

    if (this.debugMode) {
      console.log(`Rendering with class: ${inputClass}`);
    }

    return html`
      <div class="almost-done">
        <input
          class="${inputClass}"
          type="text"
          .value=${this.inputValue}
          @input=${this.handleInputChange}
          placeholder="@handle"
        />
        <input
          class="almost-done--name"
          type="text"
          .value=${this.nameValue}
          @input=${this.handleNameChange}
          placeholder="Name"
        />
        <button class="submit-button" @click=${this.handleSubmit}>Submit</button>
      </div>
    `;
  }
}
